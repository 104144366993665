@import "~@angular/material/prebuilt-themes/indigo-pink.css";
* {
  font-family: Roboto, Arial, sans-serif;
}

a {
  transition: all 0.2s ease;
  cursor: pointer;
}

.loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  background: #fff;
  opacity: 1;
  z-index: 9999;
}
.loading-container.hidden {
  opacity: 0;
  pointer-events: none;
}

html {
  font-size: 62.5%;
  line-height: 1.15;
}

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  background: linear-gradient(180deg, #ffffff 0%, #F5F9FA 100%);
  color: #000a12;
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-feature-settings: "kern", "liga";
}