@import "./styles/base";

@import "./styles/loading-container";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html {
  font-size: 62.5%;
  line-height: 1.15;
}

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  background: linear-gradient(180deg, #ffffff 0%, $ts-color-blue-50 100%);
  color: #000a12;
  font-family: Roboto,sans-serif;
  font-size: 1.4rem;
  font-feature-settings: "kern","liga";
}
