.loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .5s ease-in-out;
  background: #fff;
  opacity: 1;
  z-index: 9999;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}